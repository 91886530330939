export default {
  general: {
    status: {
      name: "Status",
      active: "Active",
      inactive: "Inactive",
      queued: "Queued",
      inProgress: "In Progress",
      assigned: "Assigned",
      pendingApproval: "Pending Approval",
      rejected: "Rejected",
      approved: "Approved",
    },
    actions: "Actions",
    platforms: {
      ios: "iOS",
      android: "Android",
      steam: "Steam",
      playStation: "PlayStation",
      xbox: "Xbox",
      epic: "Epic",
      web: "Web",
      pc: "PC",
      windows: "Windows",
      mac: "Mac",
    },
    add: "Add",
    save: "Save",
    cancel: "Cancel",
    close: "Close",
    confirm: "Confirm",
    submit: "Submit",
    done: "Done",
    next: "Next",
    enable: "Enable",
    discard: "Discard",
    chooseFile: "Choose File",
    replaceFile: "Replace File",
    remove: "Remove",
    comment: "Comment",
    error: "Error",
    edit: "Edit",
    delete: "Delete",
    deleteText:
      "Are you sure you want to delete {{item}}?\nThis will take effect immediately.",
    errorText:
      "An error has occured. Please contact support and try your request again.",
    disabledPlaceholderText: "No Concept Image Uploaded",
    dateRangeTo: "to",
    firstName: "First Name",
    middleName: "Middle Name",
    lastName: "Last Name",
    company: "Company Name",
    street1: "Street Address 1",
    street2: "Street Address 2",
    city: "City",
    region: "Region",
    postalCode: "Postal Code",
    country: "Country",
    phoneNumber: "Phone Number",
    email: "Email",
    owner: "Owner",
    billing: "Default Billing Address?",
    weekNumber: "Week {{weekNumber}}",
    noRows: "No Rows",
    noData: "No Data",
    errors: {
      minDate: "Date must be on or after {{minDate}}",
      maxDate: "Date is too far in the future",
      invalidDate: "Invalid Date",
      invalidRange: "Invalid Date Range",
      disableFuture: "Future dates are not allowed",
      shouldDisableDate: "Not a valid date for the Period selected.",
    },
  },
  wizard: {
    nextStep: "Next Step",
    prevStep: "Prev Step",
    step: "Step ",
  },
  notifications: {
    category: "Category",
    event: "Event",
    channel: "Channel",
    client: "Client",
    actions: "Actions",
    all: "All",
    notificationAdd: "Add Notification",
    notificationEventAdd: "Add Event Notification",
    notificationDelete: "Delete Notification?",
    categories: {
      aso: "ASO",
      growth: "Growth",
      account: "Account",
      creative: "Creative",
      platform: "Platform",
      null: "All",
      undefined: "All",
    },
  },
  miniHub: {
    allClientActionItems: "Action Items",
    myAssignedActionItems: "Assigned To Me",
    myWatcherActionItems: "Watching",
    userSpecificActionItems: "Current User Action Items",
    notifications: {
      title: "Notifications",
      none: "No New Notifications",
      loading: "Loading...",
      dismissAll: "Dismiss All Notifications",
      dismissText: "Are you sure you want to dismiss all notifications?",
      effect: "This will take effect immediately.",
    },
    buttons: {
      actions: "Actions",
      watching: "Watching",
      other: "Other Accounts",
    },
  },
  navigation: {
    switchTitle: "Managed Clients?",
  },
  auth: {
    invitationConfirmed: "Invitation Confirmed",
    activatedConfirm:
      "Congratulations! Your membership has been activated. Please sign in to the platform with your credentials.",
    signInStart: "Sign in to start your session",
    email: "Email",
    password: "Password",
    signIn: "Sign In",
    otpTitle: "Two-Factor Authentication",
    enterCode: "Please enter the code generated by your mobile application",
    hints: {
      passwordTitle: "Don't remember your password?",
      passwordLink: "Reset password",
    },
    oauthErrors: {
      404: "User not found. Please contact your lead!",
      412: "User email is not validated!",
      4001: "Your user is not assigned to any client account. Please contact your administrator",
      INACTIVE_ACCOUNT:
        "Your user account is disabled. Please contact your administrator",
    },
  },
  userMenu: {
    profile: "Profile",
    support: "Support Center",
    feature: "Feature Requests",
    logout: "Logout",
  },
};
