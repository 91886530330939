import { gql } from "@apollo/client";

export const UPDATE_REDIRECT_URL = gql`
  mutation updateRedirectUrl($applicationId: ID!, $redirectUrl: String!) {
    updateRedirectUrl(
      applicationId: $applicationId
      redirectUrl: $redirectUrl
    ) {
      application {
        id
      }
    }
  }
`;

export const UPDATE_SIGNIFICANCE = gql`
  mutation updateSignificance($applicationId: ID!, $significance: Float!) {
    updateSignificance(
      applicationId: $applicationId
      significance: $significance
    ) {
      application {
        id
      }
    }
  }
`;

export const UPDATE_ANDROID_APP_DETAILS = gql`
  mutation updateAndroidAppDetails(
    $id: ID!
    $publisher: String
    $ageLimit: String
    $downloads: String
  ) {
    updateAndroidAppDetails(
      id: $id
      publisher: $publisher
      ageLimit: $ageLimit
      downloads: $downloads
    ) {
      application {
        id
      }
    }
  }
`;

export const UPDATE_ANDROID_APP_RATING = gql`
  mutation updateAndroidAppRating(
    $id: ID!
    $rating: Decimal
    $numberOfRatings: Int
    $gameplayRating: Decimal
    $controlRating: Decimal
    $graphicRating: Decimal
  ) {
    updateAndroidAppRating(
      id: $id
      rating: $rating
      numberOfRatings: $numberOfRatings
      gameplayRating: $gameplayRating
      controlRating: $controlRating
      graphicRating: $graphicRating
    ) {
      application {
        id
      }
    }
  }
`;

export const UPDATE_ANDROID_GAME_INFO = gql`
  mutation updateAndroidGameInfo(
    $id: ID!
    $whatsNew: String
    $appVersion: String
    $size: Float
    $releaseDate: String
    $tags: [String]
  ) {
    updateAndroidGameInfo(
      id: $id
      whatsNew: $whatsNew
      appVersion: $appVersion
      size: $size
      releaseDate: $releaseDate
      tags: $tags
    ) {
      application {
        id
      }
    }
  }
`;

export const UPDATE_ANDROID_DEVELOPER_INFO = gql`
  mutation updateAndroidDeveloperInfo(
    $id: ID!
    $developerPage: String
    $developerEmail: String
    $developerAddress1: String
    $developerAddress2: String
    $developerCity: String
    $developerState: String
    $developerPostalCode: String
    $developerCountry: String
    $privacyPolicyUrl: String
  ) {
    updateAndroidDeveloperInfo(
      id: $id
      developerPage: $developerPage
      developerEmail: $developerEmail
      developerAddress1: $developerAddress1
      developerAddress2: $developerAddress2
      developerCity: $developerCity
      developerState: $developerState
      developerPostalCode: $developerPostalCode
      developerCountry: $developerCountry
      privacyPolicyUrl: $privacyPolicyUrl
    ) {
      application {
        id
      }
    }
  }
`;

export const UPDATE_IOS_APP_DETAILS = gql`
  mutation updateIosAppDetails(
    $id: ID!
    $publisher: String
    $rating: Decimal
    $numberOfRatings: Int
    $ageLimit: String
    $size: Float
    $category: Int
    $ranking: Int
    $copyright: String
  ) {
    updateIosAppDetails(
      id: $id
      publisher: $publisher
      rating: $rating
      numberOfRatings: $numberOfRatings
      ageLimit: $ageLimit
      size: $size
      category: $category
      ranking: $ranking
      copyright: $copyright
    ) {
      application {
        id
      }
    }
  }
`;

export const UPDATE_IOS_WHATS_NEW = gql`
  mutation updateIosWhatsNew(
    $id: ID!
    $appVersion: String
    $releaseDate: String
    $releaseNote: String
  ) {
    updateIosWhatsNew(
      id: $id
      appVersion: $appVersion
      releaseDate: $releaseDate
      releaseNote: $releaseNote
    ) {
      application {
        id
      }
    }
  }
`;

// TODO: to remove after rewrite usage in static info
export const UPDATE_APPLICATION = gql`
  mutation updateApplication(
    $clientCode: String!
    $applicationCode: String!
    $application: UpdateApplication!
  ) {
    updateApplication(
      clientCode: $clientCode
      applicationCode: $applicationCode
      application: $application
    )
  }
`;

export const SET_SHIP_DATE = gql`
  mutation createShipDate($applicationId: ID!, $date: String!) {
    createShipDate(applicationId: $applicationId, date: $date) {
      isCreated
    }
  }
`;
