import config from "config";
import ReactGA from "react-ga4";

class AnalyticsService {
  private user?: string | null;
  private currentDimensions?: any = {};

  constructor() {
    if (!config.isDev && config.enableAnalyticsTracking) {
      ReactGA.initialize("G-W41TZRS9LM");
    }
  }

  get tracker(): any {
    // @ts-ignore
    return window._paq || [];
  }

  public track(key: string, value: any = null) {
    if (value === null) {
      return this.tracker.push([key]);
    }

    return this.tracker.push([key, value]);
  }

  public trackVisit(url: string, params: any) {
    if (config.isDev || !config.enableAnalyticsTracking) return;
    if (params) {
      if (url.includes(":reportCode?")) {
        url = url.replace(":reportCode?", params.reportCode);
      }
      const paramsArray = Object.values(params);
      if (paramsArray.length > 1) {
        ReactGA.set({
          ...this.currentDimensions,
          user_properties: {
            ...this.currentDimensions.user_properties,
            EntityCode: paramsArray[1],
          },
        });
      } else {
        ReactGA.set({
          ...this.currentDimensions,
          user_properties: {
            ...this.currentDimensions.user_properties,
            EntityCode: null,
          },
        });
      }
    }
    ReactGA.send({ hitType: "pageview", page: url });

    this.track("setReferrerUrl", url);
    this.track("setCustomUrl", url);
    this.track("setDocumentTitle", document.title);
    this.track("deleteCustomVariables", "page");
    this.track("setGenerationTimeMs", 0);

    if (this.user) {
      this.track("setUserId", this.user);
    }

    this.track("trackPageView");

    const content = document.getElementById("content");
    this.track("MediaAnalytics::scanForMedia", content);
    this.track("FormAnalytics::scanForForms", content);
    this.track("trackContentImpressionsWithinNode::scanForMedia", content);
    this.track("enableLinkTracking");
  }

  public setUser(email: string, clientCode: string | null) {
    this.user = email;
    this.currentDimensions = {
      ...this.currentDimensions,
      user_id: email,
      user_properties: {
        ...(this.currentDimensions.user_properties || {}),
        ClientCode: clientCode,
        UppticUser: email?.includes("@upptic.com"),
        UserEmail: email,
      },
    };
    ReactGA.set(this.currentDimensions);
  }

  public removeUser() {
    this.user = null;
    this.track("resetUserId");
  }

  get currentUser() {
    return this.user;
  }
}

export default AnalyticsService;
